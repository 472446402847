import { Box, CircularProgress } from '@mui/material';
import React from 'react';

type CenteredCircularProgressProps = {
    height?: string,
}

function CenteredCircularProgress(props: CenteredCircularProgressProps) {
    return (
        <Box
            p={0}
            width='100%'
            height={props.height}
            display='flex'
            justifyContent='center'
            alignItems='center'
            mt={1}
        >
            <CircularProgress />
        </Box>
    );
}

export default CenteredCircularProgress;
