import { NavigateNext } from '@mui/icons-material';
import { BreadcrumbsProps, ButtonBaseProps, LinkProps, createTheme, useMediaQuery, ThemeProvider as MuiThemeProvider, PaletteMode } from '@mui/material';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';


const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

enum ThemeMode {
    light = 1,
    dark,
};

type DarkModeProviderProps = {
    children?: ReactNode | ReactNode[],
};

function ThemeProvider(props: DarkModeProviderProps) {
    const systemPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [storageMode, setStorageMode] = useState(localStorage.getItem('themeMode'));

    useEffect(() => {
        const storageHandler = () => {
            setStorageMode(localStorage.getItem('themeMode'));
        };

        window.addEventListener('storage', storageHandler);

        return () => window.removeEventListener('storage', storageHandler);
    }, []);

    const themeMode = storageMode ?
        ThemeMode[storageMode as keyof typeof ThemeMode] :
        systemPrefersDarkMode ?
            ThemeMode.dark :
            ThemeMode.light;

    const theme = useMemo(() => createTheme({
        palette: {
            mode: ThemeMode[themeMode] as PaletteMode,
        },
        components: {
            MuiLink: {
                defaultProps: {
                    component: LinkBehavior,
                } as LinkProps,
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                } as ButtonBaseProps,
            },
            MuiBreadcrumbs: {
                defaultProps: {
                    separator: <NavigateNext
                        fontSize='small'
                    />
                } as BreadcrumbsProps,
            },
        },
    }), [themeMode]);

    return (
        <MuiThemeProvider
            theme={theme}
        >
            {props.children}
        </MuiThemeProvider>
    );
}

export { ThemeProvider as DarkModeProvider, ThemeMode };
export default ThemeProvider;
