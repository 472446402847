/**
 * @generated SignedSource<<449fbd0d8fd4fc7145c4628ab873311d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_walls$data = ReadonlyArray<{
  readonly gym: {
    readonly slug: string;
  };
  readonly slug: string;
  readonly " $fragmentType": "urlsFragment_walls";
}>;
export type urlsFragment_walls$key = ReadonlyArray<{
  readonly " $data"?: urlsFragment_walls$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_walls">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "urlsFragment_walls",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GymType",
      "kind": "LinkedField",
      "name": "gym",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "WallType",
  "abstractKey": null
};
})();

(node as any).hash = "9f3021b71bb30d140add1103526f9e11";

export default node;
