import { OpenInFull } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey } from 'notistack';
import React, { useState } from 'react';


type SnackbarLinkActionProps = {
    snackbarKey: SnackbarKey,
};

function SnackbarLinkAction(props: SnackbarLinkActionProps) {
    const [link, setLink] = useState('');

    return (
        <IconButton
            ref={ref => {
                const dataAttributeLink = ref?.closest('[data-href]')?.getAttribute('data-href');
                if (dataAttributeLink) {
                    setLink(dataAttributeLink);
                }
            }}
            sx={{
                display: link ? undefined : 'none',
            }}
            href={link}
        >
            <OpenInFull />
        </IconButton>
    );
}

export default SnackbarLinkAction;
