/**
 * @generated SignedSource<<ae4cd5348726be7525031c5ec9e1dc0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_wall$data = {
  readonly gym: {
    readonly slug: string;
  };
  readonly slug: string;
  readonly " $fragmentType": "urlsFragment_wall";
};
export type urlsFragment_wall$key = {
  readonly " $data"?: urlsFragment_wall$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_wall">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "urlsFragment_wall",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GymType",
      "kind": "LinkedField",
      "name": "gym",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "WallType",
  "abstractKey": null
};
})();

(node as any).hash = "05ab5aafef7dac332d636d2e9275d6f3";

export default node;
