import { Bookmark, ExpandMore, GroupAdd, GroupRemove } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type FrequentlyAskedQuestion = {
    question: string,
    answer: string | ReactNode | ReactNode[],
};

const FrequentlyAskedQuestions = [
    {
        question: 'How do I install Plastic Project on my phone?',
        answer: <Stack spacing={2}>
            <Typography>
                Plastic Project is a progressive web app, so installation instructions
                depend on which mobile web browser you prefer. Begin by&nbsp;
                <Typography display='inline' fontWeight='bold'>
                    navigating to Plastic Project in your browser
                </Typography>.
            </Typography>
            <Typography>
                If you use&nbsp;
                <Typography display='inline' fontWeight='bold'>Android Google Chrome</Typography>
                , follow these instructions:<br />
                1. Open the&nbsp;
                <Typography display='inline' fontWeight='bold'>Settings</Typography>
                &nbsp;menu<br />
                2. Click&nbsp;
                <Typography display='inline' fontWeight='bold'>install</Typography>
            </Typography>
            <Typography>
                If you use&nbsp;
                <Typography display='inline' fontWeight='bold'>iOS Safari</Typography>
                , follow these instructions:<br />
                1. Open the&nbsp;
                <Typography display='inline' fontWeight='bold'>Share</Typography>
                &nbsp;menu at the bottom or top of the browser<br />
                2. Click&nbsp;
                <Typography display='inline' fontWeight='bold'>Add to Home Screen</Typography><br />
                3. Choose the name of the app<br />
                4. Click&nbsp;
                <Typography display='inline' fontWeight='bold'>Add</Typography>
            </Typography>
        </Stack>,
    },
    {
        question: 'How do I install Plastic Project on my computer?',
        answer: <Stack spacing={2}>
            <Typography>
                Plastic Project is a progressive web app, so installation instructions
                depend on which web browser you prefer. Begin by&nbsp;
                <Typography display='inline' fontWeight='bold'>
                    navigating to Plastic Project in your browser
                </Typography>.
            </Typography>
            <Typography>
                If you use&nbsp;
                <Typography display='inline' fontWeight='bold'>Google Chrome</Typography>
                , follow these instructions:<br />
                1. Open the&nbsp;
                <Typography display='inline' fontWeight='bold'>Settings</Typography>
                &nbsp;menu<br />
                2. Click&nbsp;
                <Typography display='inline' fontWeight='bold'>install</Typography>
            </Typography>
            <Typography>
                If you use&nbsp;
                <Typography display='inline' fontWeight='bold'>Microsoft Edge</Typography>
                , follow these instructions:<br />
                1. Click the&nbsp;
                <Typography display='inline' fontWeight='bold'>App available</Typography>
                &nbsp;icon in the address bar<br />
                2. Click&nbsp;
                <Typography display='inline' fontWeight='bold'>install</Typography>
            </Typography>
            <Typography>
                If you use&nbsp;
                <Typography display='inline' fontWeight='bold'>Safari</Typography>
                , this is not currently supported. Please install either Google Chrome, or
                Microsoft Edge and follow the appropriate instructions.
            </Typography>
        </Stack>
    },
    {
        question: 'How do I request a feature, report a bug, or provide feedback?',
        answer: 'Please email contact@plasticproject.io with any feedback or feature requests.',
    },
    {
        question: 'How do I bookmark an organization or gym?',
        answer: <Typography>
            Navigate to the organization or gym to bookmark. In the top right, there is
            is a floating blue button that moves with the screen as you scroll. Click this
            button to open up the actions. Then, click the <Bookmark fontSize='small' /> icon.
        </Typography>,
    },
    {
        question: 'How do I follow or unfollow an organization, gym, or user?',
        answer: <Typography>
            Navigate to the organization, gym, or user to follow or unfollow. In the top right,
            there is is a floating blue button that moves with the screen as you scroll. Click
            this button to open up the actions. Then, click the <GroupAdd fontSize='small' /> or
            <GroupRemove fontSize='small' /> icon which will depend on your current
            following status.
        </Typography>,
    },
    {
        question: 'How do I get my gym in Plastic Project?',
        answer: 'Currently, Plastic Project is by invite only since its in beta. If you wish to join, please email contact@plasticproject.io.',
    },
    {
        question: 'How do I setup push notifications?',
        answer: "Plastic Project doesn't currently support push notifications, but it is in the roadmap.",
    },
] as FrequentlyAskedQuestion[];

function Help() {
    return (
        <Container
            maxWidth='md'
        >
            <Stack
                spacing={3}
            >
                <Typography
                    variant='h4'
                >
                    Frequently Asked Questions
                </Typography>
                <Box>
                    {FrequentlyAskedQuestions.map((faq, index) => {
                        return (
                            <Accordion
                                key={index}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography
                                        variant='h5'
                                    >
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {typeof (faq.answer) === 'string' ? <Typography>
                                        {faq.answer}
                                    </Typography> : faq.answer}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Box>
            </Stack>
        </Container>
    );
}

export default Help;
