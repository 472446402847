import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';


type SnackbarDismissActionProps = {
    snackbarKey: SnackbarKey,
};

function SnackbarDismissAction(props: SnackbarDismissActionProps) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton
            onClick={() => {
                closeSnackbar(props.snackbarKey);
            }}
        >
            <Close />
        </IconButton>
    );
}

export default SnackbarDismissAction;
