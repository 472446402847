import {
    Environment,
    FetchFunction,
    Network,
    RecordSource,
    Store,
} from 'relay-runtime';

const fetchFn: FetchFunction = async (request, variables, cacheConfig, uploadables) => {
    var body: FormData | string = JSON.stringify({
        query: request.text,
        variables,
    });
    if (uploadables && Object.keys(uploadables).length > 0) {
        const formData = new FormData();
        formData.append('operations', body);
        const map: Record<string, string[]> = {};

        Object.keys(uploadables).forEach(key => {
            map[key] = [key];
        });
        formData.append('map', JSON.stringify(map));
        Object.keys(uploadables).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
                formData.append(key, uploadables[key]);
            }
        });

        body = formData;
    }
    const resp = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT!, {
        method: "POST",
        headers: {
            Accept: "application/json",
            ...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
        },
        body,
        credentials: 'include',
    });

    return await resp.json();
};

function createRelayEnvironment() {
    return new Environment({
        network: Network.create(fetchFn),
        store: new Store(new RecordSource()),
    });
}

const RelayEnvironment = createRelayEnvironment();

export { RelayEnvironment };
