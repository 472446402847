/**
 * @generated SignedSource<<ec1e1f4ea93d10ffbe7a404230b9fc6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_video$data = {
  readonly climb: {
    readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_climb">;
  } | null;
  readonly id: string;
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_user">;
  };
  readonly " $fragmentType": "urlsFragment_video";
};
export type urlsFragment_video$key = {
  readonly " $data"?: urlsFragment_video$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_video">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "urlsFragment_video",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "urlsFragment_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClimbType",
      "kind": "LinkedField",
      "name": "climb",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "urlsFragment_climb"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "VideoType",
  "abstractKey": null
};

(node as any).hash = "fe3b469ffe962911e232ead3d0763593";

export default node;
