/**
 * @generated SignedSource<<0b0fdcbf766e562325ac1ce6952d7d01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_post$data = {
  readonly gym: {
    readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_gym">;
  } | null;
  readonly id: string;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_organization">;
  } | null;
  readonly " $fragmentType": "urlsFragment_post";
};
export type urlsFragment_post$key = {
  readonly " $data"?: urlsFragment_post$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_post">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "urlsFragment_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationType",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "urlsFragment_organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GymType",
      "kind": "LinkedField",
      "name": "gym",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "urlsFragment_gym"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PostType",
  "abstractKey": null
};

(node as any).hash = "2c4e45c58bfdee3194a85807d6b44484";

export default node;
