import './App.css';
import React, { useCallback } from 'react';
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from 'react-router-dom';
import ErrorPage from './components/utilities/ErrorPage';
import Suspense from './components/utilities/Suspense';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { AboutPath, ClimbPath, DiscoverPath, GymPath, GymSettingTabClientFeedbackPath, GymSettingTabClimbTargetsPath, GymSettingTabPath, GymSettingTabSetterBreakdownPath, GymSettingTabSettingPlansPath, HelpPath, HomePath, LoginPath, LogoutPath, OrganizationPath, ResetPasswordPath, SettingsPath, StopWatchPath, SwapEmailPath, TimerPath, UserPath, VerificationPath, WelcomePath } from './utilities/urls';
import MapkitProvider from './components/utilities/MapkitProvider';
import About from './components/About';
import Help from './components/Help';
import ThemeProvider from './components/utilities/ThemeProvider';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import SnackbarDismissAction from './components/utilities/SnackbarDismissAction';
import SnackbarLinkAction from './components/utilities/SnackbarLinkAction';

function App() {
    const defaultSnackbarActions = useCallback((key: SnackbarKey) => {
        return [
            <SnackbarLinkAction
                key='link'
                snackbarKey={key}
            />,
            <SnackbarDismissAction
                key='dismiss'
                snackbarKey={key}
            />
        ];
    }, []);

    const router = createBrowserRouter([
        {
            path: '',
            element: (
                <ThemeProvider>
                    <CssBaseline />
                    <SnackbarProvider
                        maxSnack={3}
                        action={defaultSnackbarActions}
                    >
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </SnackbarProvider>
                </ThemeProvider>
            ),
            errorElement: (
                <ThemeProvider>
                    <CssBaseline />
                    <SnackbarProvider
                        maxSnack={3}
                        action={defaultSnackbarActions}
                    >
                        <ErrorPage />
                    </SnackbarProvider>
                </ThemeProvider>
            ),
            children: [
                {
                    path: WelcomePath,
                    lazy: async () => await import(
                        /* webpackChunkName: "app" */
                        './components/Welcome'
                    ),
                },
                {
                    path: '/',
                    lazy: async () => await import(
                        /* webpackChunkName: "app" */
                        './components/Layout'
                    ),
                    children: [
                        {
                            path: HomePath,
                            lazy: async () => await import(
                                /* webpackChunkName: "app" */
                                './components/Home'
                            ),
                        },
                        {
                            path: DiscoverPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "app" */
                                './components/Discover'
                            ),
                        },
                        {
                            path: OrganizationPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "organization" */
                                './components/organization/Organization'
                            ),
                            children: [
                                {
                                    index: true,
                                    element: <Navigate to='activity' replace />
                                },
                                {
                                    path: 'activity',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "organization" */
                                        './components/organization/OrganizationActivityTab'
                                    ),
                                },
                                {
                                    path: 'posts/:postID?',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "organization" */
                                        './components/organization/OrganizationPostsTab'
                                    )
                                },
                                {
                                    path: 'gyms',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "organization" */
                                        './components/organization/OrganizationGymsTab'
                                    )
                                },
                                {
                                    path: 'users',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "organization" */
                                        './components/organization/OrganizationUsersTab'
                                    ),
                                    children: [
                                        {
                                            path: ':username',
                                            lazy: async () => await import(
                                                /* webpackChunkName: "organization" */
                                                './components/organization/OrganizationRelationship'
                                            )
                                        },
                                    ],
                                },
                                {
                                    path: 'images',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "organization" */
                                        './components/organization/OrganizationImagesTab'
                                    ),
                                    children: [
                                        {
                                            path: ':imageID',
                                            lazy: async () => await import(
                                                /* webpackChunkName: "organization" */
                                                './components/organization/OrganizationImage'
                                            )
                                        },
                                    ],
                                },
                                {
                                    path: 'waivers',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "organization" */
                                        './components/organization/OrganizationWaiversTab'
                                    )
                                }
                            ],
                        },
                        {
                            path: GymPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "gym" */
                                './components/gym/Gym'
                            ),
                            children: [
                                {
                                    index: true,
                                    element: <Navigate to='walls' replace />,
                                },
                                {
                                    path: 'walls',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymWallsTab'
                                    ),
                                    children: [
                                        {
                                            path: ':wallSlug',
                                            lazy: async () => await import(
                                                /* webpackChunkName: "gym" */
                                                './components/wall/WallAccordionDetails'
                                            )
                                        },
                                    ],
                                },
                                {
                                    path: 'climbs',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymClimbsTab'
                                    ),
                                },
                                {
                                    path: 'leaderboard',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymLeaderboardTab'
                                    ),
                                },
                                {
                                    path: 'insights',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymInsightsTab'
                                    ),
                                },
                                {
                                    path: GymSettingTabPath,
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymSettingTab'
                                    ),
                                    children: [
                                        {
                                            path: GymSettingTabSettingPlansPath,
                                            lazy: async () => await import(
                                                /* webpackChunkName: "gym" */
                                                './components/gym/GymSettingTabSettingPlans'
                                            ),
                                            children: [
                                                {
                                                    path: ':settingPlanId',
                                                    lazy: async () => await import(
                                                        /* webpackChunkName: "gym" */
                                                        './components/plan/SettingPlan'
                                                    ),
                                                },
                                            ],
                                        },
                                        {
                                            path: GymSettingTabClimbTargetsPath,
                                            lazy: async () => await import(
                                                /* webpackChunkName: "gym" */
                                                './components/gym/GymSettingTabClimbTargets'
                                            ),
                                        },
                                        {
                                            path: GymSettingTabSetterBreakdownPath,
                                            lazy: async () => await import(
                                                /* webpackChunkName: "gym" */
                                                './components/gym/GymSettingTabSetterBreakdown'
                                            ),
                                        },
                                        {
                                            path: GymSettingTabClientFeedbackPath,
                                            lazy: async () => await import(
                                                /* webpackChunkName: "gym" */
                                                './components/gym/GymSettingTabClientFeedback'
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: 'activity',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymActivityTab'
                                    ),
                                },
                                {
                                    path: 'posts/:postID?',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymPostsTab'
                                    ),
                                },
                                {
                                    path: 'users',
                                    lazy: async () => import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymUsersTab'
                                    ),
                                    children: [
                                        {
                                            path: ':username',
                                            lazy: async () => await import(
                                                /* webpackChunkName: "gym" */
                                                './components/gym/GymRelationship'
                                            ),
                                        }
                                    ],
                                },
                                {
                                    path: 'images',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "gym" */
                                        './components/gym/GymImagesTab'
                                    ),
                                    children: [
                                        {
                                            path: ':imageID',
                                            lazy: async () => await import(
                                                /* webpackChunkName: "gym" */
                                                './components/gym/GymImage'
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: ClimbPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "climb" */
                                './components/climb/Climb'
                            ),
                            children: [
                                {
                                    path: 'images/:imageID',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "climb" */
                                        './components/climb/ClimbImage'
                                    ),
                                },
                                {
                                    path: 'videos/:videoID',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "climb" */
                                        './components/climb/ClimbVideo'
                                    ),
                                },
                            ],
                        },
                        {
                            path: UserPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "userprofile" */
                                './components/userprofile/UserProfile'
                            ),
                            children: [
                                {
                                    index: true,
                                    element: <Navigate to='activity' replace />
                                },
                                {
                                    path: 'activity',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileActivityTab'
                                    ),
                                },
                                {
                                    path: 'logs',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileClimbLogsTab'
                                    ),
                                },
                                {
                                    path: 'insights',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileInsightsTab'
                                    ),
                                },
                                {
                                    path: 'setting',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileSettingTab'
                                    ),
                                },
                                {
                                    path: 'rankings',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileRankingsTab'
                                    ),
                                },
                                {
                                    path: 'images',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileImagesTab'
                                    ),
                                    children: [
                                        {
                                            path: ':imageID',
                                            lazy: async () => await import(
                                                /* webpackChunkName: "userprofile" */
                                                './components/userprofile/UserProfileImage'
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: 'videos',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileVideosTab'
                                    ),
                                    children: [
                                        {
                                            path: ':videoID',
                                            lazy: async () => await import(
                                                /* webpackChunkName: "userprofile" */
                                                './components/userprofile/UserProfileVideo'
                                            ),
                                        }
                                    ],
                                },
                                {
                                    path: 'followers',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileFollowersTab'
                                    ),
                                },
                                {
                                    path: 'following',
                                    lazy: async () => await import(
                                        /* webpackChunkName: "userprofile" */
                                        './components/userprofile/UserProfileFollowingTab'
                                    ),
                                },
                            ],
                        },
                        {
                            path: StopWatchPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "tools" */
                                './components/tools/Stopwatch'
                            ),
                        },
                        {
                            path: TimerPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "tools" */
                                './components/tools/Timer'
                            ),
                        },
                        {
                            path: VerificationPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "account" */
                                './components/Verify'
                            ),
                        },
                        {
                            path: ResetPasswordPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "account" */
                                './components/account/ResetPassword'
                            ),
                        },
                        {
                            path: SwapEmailPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "account" */
                                './components/account/SwapEmail'
                            ),
                        },
                        {
                            path: SettingsPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "account" */
                                './components/account/Settings'
                            ),
                        },
                        {
                            path: LoginPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "account" */
                                './components/account/Login'
                            ),
                        },
                        {
                            path: LogoutPath,
                            lazy: async () => await import(
                                /* webpackChunkName: "account" */
                                './components/account/Logout'
                            ),
                        },
                        {
                            path: AboutPath,
                            element: <About />,
                        },
                        {
                            path: HelpPath,
                            element: <Help />,
                        },
                    ],
                },
            ],
        }
    ]);

    return (
        <LocalizationProvider
            dateAdapter={AdapterLuxon}
        >
            <MapkitProvider>
                <RouterProvider
                    router={router}
                />
            </MapkitProvider>
        </LocalizationProvider>
    );
}

export default App;
