import React from 'react';
import { Stack } from '@mui/material';
import { BugReport } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

function ErrorPage() {
    const error = useRouteError();

    return (
        <Box
            display='flex'
            justifyContent='center'
        >
            <Stack
                spacing={3}
                alignItems='center'
                justifyContent='center'
            >
                <BugReport
                    sx={{
                        width: '100%',
                        height: 'auto',
                    }} />
                <Typography
                    align='center'
                    variant='h2'
                >
                    Oops, there was an error!
                </Typography>
                {isRouteErrorResponse(error) ? <Typography
                    align='center'
                    variant='h5'
                >
                    {error.error?.message || error.statusText}
                </Typography> : undefined}
            </Stack>
        </Box>
    )
}

export default ErrorPage;
