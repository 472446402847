import { Email, ExpandMore, Language, Place } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import TermsOfService from './account/TermsOfService';
import PrivacyPolicy from './account/PrivacyPolicy';
import Grid2 from '@mui/material/Unstable_Grid2';


function About() {
    return (
        <Container
            maxWidth='md'
        >
            <Stack spacing={3}>
                <Typography
                    variant='h4'
                    textAlign='center'
                >
                    Plastic Project LLC
                </Typography>
                <Grid2
                    container
                    spacing={2}
                    columns={12}
                >
                    <Grid2
                        xs={12}
                        sm={4}
                    >
                        <Stack
                            spacing={1}
                            display='flex'
                            alignItems='center'
                        >
                            <Language />
                            <Typography
                                variant='h5'
                            >
                                Website
                            </Typography>
                            <Typography
                                textAlign='center'
                            >
                                plasticproject.io
                            </Typography>
                        </Stack>
                    </Grid2>
                    <Grid2
                        xs={12}
                        sm={4}
                    >
                        <Stack
                            spacing={1}
                            display='flex'
                            alignItems='center'
                        >
                            <Email />
                            <Typography
                                variant='h5'
                            >
                                Email
                            </Typography>
                            <Typography
                                textAlign='center'
                            >
                                contact@plasticproject.io
                            </Typography>
                        </Stack>
                    </Grid2>
                    <Grid2
                        xs={12}
                        sm={4}
                    >
                        <Stack
                            spacing={1}
                            display='flex'
                            alignItems='center'
                        >
                            <Place />
                            <Typography
                                variant='h5'
                            >
                                Address
                            </Typography>
                            <Typography
                                textAlign='center'
                            >
                                16192 Coastal Hwy, Lewes, DE 19958
                            </Typography>
                        </Stack>
                    </Grid2>
                </Grid2>
                <Box>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography
                                variant='h5'
                            >
                                Terms of Service
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TermsOfService />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography
                                variant='h5'
                            >
                                Privacy Policy
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PrivacyPolicy />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Stack>
        </Container>
    );
}

export default About;
