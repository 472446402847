/**
 * @generated SignedSource<<b4b467715ca1e4c49765edc7ac6a7c8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MapkitProviderQuery$variables = {};
export type MapkitProviderQuery$data = {
  readonly mapkit: {
    readonly expiration: any;
    readonly token: string;
  };
};
export type MapkitProviderQuery = {
  response: MapkitProviderQuery$data;
  variables: MapkitProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MapkitType",
    "kind": "LinkedField",
    "name": "mapkit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expiration",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MapkitProviderQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MapkitProviderQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5fe8b32d4cef557d2fd5969cf078369b",
    "id": null,
    "metadata": {},
    "name": "MapkitProviderQuery",
    "operationKind": "query",
    "text": "query MapkitProviderQuery {\n  mapkit {\n    token\n    expiration\n  }\n}\n"
  }
};
})();

(node as any).hash = "625f828d14a5056c52758dd1668aabac";

export default node;
