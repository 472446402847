/**
 * @generated SignedSource<<b3bf04217e54a5f216a0e0ef09a9288d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_organizations$data = ReadonlyArray<{
  readonly slug: string;
  readonly " $fragmentType": "urlsFragment_organizations";
}>;
export type urlsFragment_organizations$key = ReadonlyArray<{
  readonly " $data"?: urlsFragment_organizations$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_organizations">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "urlsFragment_organizations",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};

(node as any).hash = "59791c73759755e37785304f3f8ab3e8";

export default node;
