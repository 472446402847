/**
 * @generated SignedSource<<a719b05b9563310ab2fcc630d9133985>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_climbs$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentType": "urlsFragment_climbs";
}>;
export type urlsFragment_climbs$key = ReadonlyArray<{
  readonly " $data"?: urlsFragment_climbs$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_climbs">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "urlsFragment_climbs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ClimbType",
  "abstractKey": null
};

(node as any).hash = "20631fc5971784e185d2a58983e9c4ae";

export default node;
