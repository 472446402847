import React, { ReactNode, useCallback, useRef } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { MapkitProviderQuery as MapkitProviderQueryType } from './__generated__/MapkitProviderQuery.graphql';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { DateTime } from 'luxon';
import { useEffectOnce } from 'usehooks-ts';


const MapkitProviderQuery = graphql`
query MapkitProviderQuery {
    mapkit {
        token
        expiration
    }
}
`;

let isLoaded = null as Promise<void> | null;

const MapkitContext = React.createContext(null as (() => Promise<void>) | null);

function useMapkitContext() {
    return React.useContext(MapkitContext);
}

type MapkitProviderProps = {
    children?: ReactNode | ReactNode[],
};

function MapkitProvider(props: MapkitProviderProps) {
    const environment = useRelayEnvironment();
    const tokenRef = useRef(null as { token: string, expiration: DateTime } | null);

    const clearMapkitStorageKeys = () => {
        localStorage.removeItem('mapkit-access-token');
        localStorage.removeItem('mapkit-access-token-expiration');
    };

    useEffectOnce(() => {
        const token = localStorage.getItem('mapkit-access-token');
        const expiration = DateTime.fromISO(localStorage.getItem('mapkit-access-token-expiration') ?? '');

        if (token && expiration.isValid && expiration > DateTime.now()) {
            tokenRef.current = {
                token,
                expiration,
            };
        } else {
            clearMapkitStorageKeys();
        }
    });

    const load = useCallback(async () => {
        if (tokenRef.current !== null && tokenRef.current.expiration < DateTime.now()) {
            tokenRef.current = null;
        }

        if (tokenRef.current === null) {
            await fetchQuery<MapkitProviderQueryType>(
                environment,
                MapkitProviderQuery,
                {},
                { fetchPolicy: 'network-only' }
            ).toPromise().then(data => {
                localStorage.setItem('mapkit-access-token', data!.mapkit.token);
                localStorage.setItem('mapkit-access-token-expiration', data!.mapkit.expiration);

                tokenRef.current = {
                    token: data!.mapkit.token,
                    expiration: DateTime.fromISO(data!.mapkit.expiration),
                };
            });
        }

        if (isLoaded === null) {
            isLoaded = new Promise((resolve) => {
                const script = document.createElement('script');
                script.addEventListener('load', () => {
                    mapkit.init({
                        authorizationCallback: (done) => done(tokenRef.current!.token),
                    });

                    resolve();
                }, { once: true });
                script.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
                script.crossOrigin = 'anonymous';
                document.head.appendChild(script);
            });
        }

        return isLoaded;
    }, [environment]);

    return (
        <MapkitContext.Provider value={load}>
            {props.children}
        </MapkitContext.Provider>
    );
}

export { MapkitProvider, useMapkitContext };
export default MapkitProvider;
