/**
 * @generated SignedSource<<c195bd649c5e40ea59fa33739fdad70e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_gyms$data = ReadonlyArray<{
  readonly slug: string;
  readonly " $fragmentType": "urlsFragment_gyms";
}>;
export type urlsFragment_gyms$key = ReadonlyArray<{
  readonly " $data"?: urlsFragment_gyms$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_gyms">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "urlsFragment_gyms",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "GymType",
  "abstractKey": null
};

(node as any).hash = "a52821bd753865edd2f8f6df4794b735";

export default node;
