import graphql from 'babel-plugin-relay/macro';
import { urlsFragment_organization$key } from './__generated__/urlsFragment_organization.graphql';
import { useFragment } from 'react-relay';
import { urlsFragment_gym$key } from './__generated__/urlsFragment_gym.graphql';
import { urlsFragment_wall$key } from './__generated__/urlsFragment_wall.graphql';
import { urlsFragment_climb$key } from './__generated__/urlsFragment_climb.graphql';
import { urlsFragment_image$key } from './__generated__/urlsFragment_image.graphql';
import { urlsFragment_post$key } from './__generated__/urlsFragment_post.graphql';
import { urlsFragment_user$key } from './__generated__/urlsFragment_user.graphql';
import { urlsFragment_video$key } from './__generated__/urlsFragment_video.graphql';
import { urlsFragment_climbs$key } from './__generated__/urlsFragment_climbs.graphql';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { urlsFragment_walls$key } from './__generated__/urlsFragment_walls.graphql';
import { urlsFragment_gyms$key } from './__generated__/urlsFragment_gyms.graphql';
import { urlsFragment_organizations$key } from './__generated__/urlsFragment_organizations.graphql';


const WelcomePath = '/welcome';
const AboutPath = '/about';
const HelpPath = '/help';
const DiscoverPath = '/discover';
const HomePath = '/';
const VerificationPathWithoutParams = '/verify';
const VerificationPath = `${VerificationPathWithoutParams}/:token?`;
const ResetPasswordPathWithoutParams = '/resetpassword';
const ResetPasswordPath = `${ResetPasswordPathWithoutParams}/:token?`;
const SwapEmailPath = '/swapemail/:token';
const LoginPath = '/login';
const LogoutPath = '/logout';
const SettingsPath = '/settings';
const StopWatchPath = '/tools/stopwatch';
const TimerPath = '/tools/timer';
const OrganizationPath = '/organization/:slug';
const OrganizationActivityTabPath = 'activity';
const OrganizationPostsTabPath = 'posts';
const OrganizationGymsTabPath = 'gyms';
const OrganizationUsersTabPath = 'users';
const OrganizationImagesTabPath = 'images';
const OrganizationWaiverTabPath = 'waivers';
const GymPath = '/gym/:slug';
const GymWallsTabPath = 'walls';
const GymClimbsTabPath = 'climbs';
const GymLeaderboardTabPath = 'leaderboard';
const GymInsightsTabPath = 'insights';
const GymSettingTabPath = 'setting';
const GymSettingTabSettingPlansPath = 'plans';
const GymSettingTabClimbTargetsPath = 'current';
const GymSettingTabSetterBreakdownPath = 'setters';
const GymSettingTabClientFeedbackPath = 'feedback';
const GymActivityTabPath = 'activity';
const GymPostsTabPath = 'posts';
const GymUsersTabPath = 'users';
const GymImagesTabPath = 'images';
const ClimbPath = '/climb/:id';
const UserPath = '/user/:username';
const UserActivityTabPath = 'activity';
const UserLogsTabPath = 'logs';
const UserInsightsTabPath = 'insights';
const UserSettingTabPath = 'setting';
const UserRankingsTabPath = 'rankings';
const UserImagesTabPath = 'images';
const UserVideosTabPath = 'videos';
const UserFollowersTabPath = 'followers';
const UserFollowingTabPath = 'following';

const urlsFragment_organization = graphql`
fragment urlsFragment_organization on OrganizationType {
    slug
}
`;

const useOrganizationUrl = (key: urlsFragment_organization$key | null) => {
    const organization = useFragment(urlsFragment_organization, key);
    return organization ? `/organization/${organization.slug}` : '';
}


const urlsFragment_organizations = graphql`
fragment urlsFragment_organizations on OrganizationType @relay(plural: true) {
    slug
}
`;

const useOrganizationUrls = (key: urlsFragment_organizations$key | null) => {
    const organizations = useFragment(urlsFragment_organizations, key);
    return organizations ? organizations.map(organization => `/organization/${organization.slug}`) : [];
}

const useOrganizationTabUrls = (key: urlsFragment_organization$key) => {
    const organization = useFragment(urlsFragment_organization, key);
    const index = `/organization/${organization.slug}`;
    return {
        index,
        activity: `${index}/${OrganizationActivityTabPath}`,
        posts: `${index}/${OrganizationPostsTabPath}`,
        gyms: `${index}/${OrganizationGymsTabPath}`,
        users: `${index}/${OrganizationUsersTabPath}`,
        images: `${index}/${OrganizationImagesTabPath}`,
        waiver: `${index}/${OrganizationWaiverTabPath}`,
    };
}

const urlsFragment_gym = graphql`
fragment urlsFragment_gym on GymType {
    slug
}
`;

const useGymUrl = (key: urlsFragment_gym$key | null) => {
    const gym = useFragment(urlsFragment_gym, key);
    return gym ? `/gym/${gym.slug}` : '';
};

const urlsFragment_gyms = graphql`
fragment urlsFragment_gyms on GymType @relay(plural: true) {
    slug
}
`;

const useGymUrls = (key: urlsFragment_gyms$key | null) => {
    const gyms = useFragment(urlsFragment_gyms, key);
    return gyms ? gyms.map(gym => `/gym/${gym.slug}`) : [];
}

const useGymTabUrls = (key: urlsFragment_gym$key) => {
    const gym = useFragment(urlsFragment_gym, key);
    const index = `/gym/${gym.slug}`;
    return {
        index,
        walls: `${index}/${GymWallsTabPath}`,
        climbs: `${index}/${GymClimbsTabPath}`,
        leaderboard: `${index}/${GymLeaderboardTabPath}`,
        insights: `${index}/${GymInsightsTabPath}`,
        setting: {
            index: `${index}/${GymSettingTabPath}`,
            plans: `${index}/${GymSettingTabPath}/${GymSettingTabSettingPlansPath}`,
            current: `${index}/${GymSettingTabPath}/${GymSettingTabClimbTargetsPath}`,
            setterBreakdown: `${index}/${GymSettingTabPath}/${GymSettingTabSetterBreakdownPath}`,
            clientFeedback: `${index}/${GymSettingTabPath}/${GymSettingTabClientFeedbackPath}`,
        },
        activity: `${index}/${GymActivityTabPath}`,
        posts: `${index}/${GymPostsTabPath}`,
        users: `${index}/${GymUsersTabPath}`,
        images: `${index}/${GymImagesTabPath}`,
    };
};

const urlsFragment_wall = graphql`
fragment urlsFragment_wall on WallType {
    slug
    gym {
        slug
    }
}
`;

const useWallUrl = (key: urlsFragment_wall$key | null) => {
    const wall = useFragment(urlsFragment_wall, key);
    return wall ? `/gym/${wall.gym.slug}/walls/${wall.slug}` : '';
};

const urlsFragment_walls = graphql`
fragment urlsFragment_walls on WallType @relay(plural: true) {
    slug
    gym {
        slug
    }
}
`;

const useWallUrls = (key: urlsFragment_walls$key | null) => {
    const walls = useFragment(urlsFragment_walls, key);
    return walls ? walls.map(wall => `/gym/${wall.gym.slug}/walls/${wall.slug}`) : [];
};

const urlsFragment_climb = graphql`
fragment urlsFragment_climb on ClimbType {
    id
}
`;

const useClimbUrl = (key: urlsFragment_climb$key | null) => {
    const climb = useFragment(urlsFragment_climb, key);
    return climb ? `/climb/${climb.id}` : '';
};

const urlsFragment_climbs = graphql`
fragment urlsFragment_climbs on ClimbType @relay(plural: true) {
    id
}
`;

const useClimbUrls = (key: urlsFragment_climbs$key | null) => {
    const climbs = useFragment(urlsFragment_climbs, key);
    return climbs ? climbs.map(climb => `/climb/${climb.id}`) : [];
};

const urlsFragment_image = graphql`
fragment urlsFragment_image on ImageType {
    id
    user {
        ...urlsFragment_user
    }
    organization {
        ...urlsFragment_organization
    }
    gym {
        ...urlsFragment_gym
    }
    wall {
        ...urlsFragment_wall
    }
    climb {
        ...urlsFragment_climb
    }
}
`;
const useImageUrl = (key: urlsFragment_image$key | null, userMode: boolean = false) => {
    const image = useFragment(urlsFragment_image, key);
    const user = useUserTabUrls(image?.user || null);
    const organization = useOrganizationUrl(image?.organization || null);
    const gym = useGymUrl(image?.gym || null);
    const wall = useWallUrl(image?.wall || null);
    const climb = useClimbUrl(image?.climb || null);

    if (image === null) {
        return '';
    }

    if (userMode) {
        return `${user.images}/${image.id}`;
    }

    const prefix = [organization, gym, wall, climb].find(prefix => prefix);
    if (prefix === undefined) {
        throw new Error('Unreachable code');
    }

    return `${prefix}/images/${image.id}`;
};

const urlsFragment_post = graphql`
fragment urlsFragment_post on PostType {
    id
    organization {
        ...urlsFragment_organization
    }
    gym {
        ...urlsFragment_gym
    }
}
`;
const usePostUrl = (key: urlsFragment_post$key | null) => {
    const post = useFragment(urlsFragment_post, key);
    const organization = useOrganizationUrl(post?.organization || null);
    const gym = useGymUrl(post?.gym || null);

    if (post === null) {
        return '';
    }

    const prefix = [organization, gym].find(prefix => prefix)
    if (prefix === undefined) {
        throw new Error('Unreachable code');
    }

    return `${prefix}/posts/${post.id}`;
};

const urlsFragment_user = graphql`
fragment urlsFragment_user on UserType {
    username
}
`;
const useUserUrl = (key: urlsFragment_user$key | null) => {
    const user = useFragment(urlsFragment_user, key);
    return user ? `/user/${user.username}` : '';
}

const useUserTabUrls = (key: urlsFragment_user$key | null) => {
    const user = useFragment(urlsFragment_user, key);
    const index = `/user/${user?.username}`;
    return user ? {
        index,
        activity: `${index}/${UserActivityTabPath}`,
        logs: `${index}/${UserLogsTabPath}`,
        insights: `${index}/${UserInsightsTabPath}`,
        setting: `${index}/${UserSettingTabPath}`,
        rankings: `${index}/${UserRankingsTabPath}`,
        images: `${index}/${UserImagesTabPath}`,
        videos: `${index}/${UserVideosTabPath}`,
        followers: `${index}/${UserFollowersTabPath}`,
        following: `${index}/${UserFollowingTabPath}`,
    } : {
        index: '',
        activity: '',
        logs: '',
        insights: '',
        setting: '',
        rankings: '',
        images: '',
        videos: '',
        followers: '',
        following: '',
    };
}

const urlsFragment_video = graphql`
fragment urlsFragment_video on VideoType {
    id
    user {
        ...urlsFragment_user
    }
    climb {
        ...urlsFragment_climb
    }
}
`;
const useVideoUrl = (key: urlsFragment_video$key | null, resourceUrl?: boolean) => {
    const video = useFragment(urlsFragment_video, key);
    const climbUrl = useClimbUrl(video?.climb ?? null);
    const userProfileUrl = useUserTabUrls(video?.user ?? null);

    if (video === null) {
        return '';
    }
    return resourceUrl === false ? `${userProfileUrl.videos}/${video.id}` : `${climbUrl}/videos/${video.id}`;
}

function trimTrailingSlash(input: string) {
    return input.endsWith('/') ? input.slice(0, input.length - 1) : input;
}

const usePathnameWithoutTrailingSlashes = () => {
    const { pathname } = useLocation();
    const result = useMemo(() => {
        return trimTrailingSlash(pathname);
    }, [pathname]);

    return result;
}

const slugRegex = /^[-a-zA-Z0-9_]{1,50}$/;
function validateSlug(slug: string) {
    return slug === '' || Boolean(slug.match(slugRegex));
}

export {
    HomePath,
    AboutPath,
    HelpPath,
    VerificationPathWithoutParams,
    VerificationPath,
    ResetPasswordPathWithoutParams,
    ResetPasswordPath,
    SwapEmailPath,
    WelcomePath,
    DiscoverPath,
    LoginPath,
    LogoutPath,
    StopWatchPath,
    TimerPath,
    SettingsPath,
    UserPath,
    OrganizationPath,
    GymPath,
    GymSettingTabPath,
    GymSettingTabSettingPlansPath,
    GymSettingTabClimbTargetsPath,
    GymSettingTabSetterBreakdownPath,
    GymSettingTabClientFeedbackPath,
    ClimbPath,
    useUserUrl,
    useUserTabUrls,
    useOrganizationUrl,
    useOrganizationUrls,
    useOrganizationTabUrls,
    useGymUrl,
    useGymUrls,
    useGymTabUrls,
    useWallUrl,
    useWallUrls,
    useClimbUrl,
    useClimbUrls,
    useImageUrl,
    usePostUrl,
    useVideoUrl,
    usePathnameWithoutTrailingSlashes,
    validateSlug,
};
