/**
 * @generated SignedSource<<3a1dc5b2b249525425ab4ff87a413d02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_climb$data = {
  readonly id: string;
  readonly " $fragmentType": "urlsFragment_climb";
};
export type urlsFragment_climb$key = {
  readonly " $data"?: urlsFragment_climb$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_climb">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "urlsFragment_climb",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ClimbType",
  "abstractKey": null
};

(node as any).hash = "3152796568c6d0e063461e3dd090ca60";

export default node;
