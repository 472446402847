import React, { ReactNode, useRef } from 'react';
import CenteredCircularProgress from '../display/CenteredCircularProgress';
import { Box, Fade } from '@mui/material';

type SuspenseProps = {
    height?: number,
    children: ReactNode,
    disablePreserveHeight?: true,
}

function Suspense(props: SuspenseProps) {
    const heightRef = useRef(undefined as number | undefined);
    const containerRef = useRef(undefined as HTMLDivElement | undefined);

    const fallback = (
        <Box
            height={(props.disablePreserveHeight ? undefined : heightRef.current) ?? props.height}
        >
            <CenteredCircularProgress />
        </Box>
    );

    if (containerRef.current) {
        heightRef.current = containerRef.current.offsetHeight;
    }

    return (
        <React.Suspense
            fallback={fallback}
        >
            <Fade
                in={true}
                appear={true}
                timeout={350}
            >
                <Box
                    ref={containerRef}
                >
                    {props.children}
                </Box>
            </Fade>
        </React.Suspense>
    )
}

export default Suspense;
