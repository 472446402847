/**
 * @generated SignedSource<<21663714e4bb1ae037caaab9c9533be1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type urlsFragment_organization$data = {
  readonly slug: string;
  readonly " $fragmentType": "urlsFragment_organization";
};
export type urlsFragment_organization$key = {
  readonly " $data"?: urlsFragment_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"urlsFragment_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "urlsFragment_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "OrganizationType",
  "abstractKey": null
};

(node as any).hash = "c8677627b8fe2f271d17491eadd72fb6";

export default node;
