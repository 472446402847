import { Box } from '@mui/material';
import React from 'react';

function TermsOfService() {
    return (
        <Box
            height='80vh'
        >
            <iframe
                title='Terms of Service'
                src={`${process.env.PUBLIC_URL}/tos.html`}
                width='100%'
                height='100%'
                style={{
                    border: 0,
                }}
            />
        </Box>
    );
}

export default TermsOfService;
